import axios from 'axios'
import sortPlatforms from './sortPlatforms'

const getPagarmePlatforms = async () => {
  const response = await axios.get(
    'https://api.mundipagg.com/utilities/v2/maintainers',
  )

  const mapPlatform = platform => ({
    businessVersion: platform.business_version,
    label: platform.name,
    status: platform.status,
    value: platform.name,
  })

  const platformsNames = response.data.map(mapPlatform).sort(sortPlatforms)

  const filteredActivePlataforms = platformsNames.filter(
    platform => platform.status === 'active',
  )

  return filteredActivePlataforms
}

export default getPagarmePlatforms
